import { DEBT_GOAL } from '../../components/Optimizer/types';
import { IOnboardingData, ONBOARDING_ACTIONS } from './onboarding.actions';

export const initialState: IOnboardingData = {
  firebaseRef: null,
  hasFatalError: false,
  loading: false,
  initialized: false,
  goal: null,
  sendEmail: true,
  primaryPhone: null,
  sendSMS: true,
  source: null,
  livingSituation: {
    isRenting: true,
    homeValue: null,
    rent: null,
  },
  dob: null,
  income: {
    annual: null,
    savings: null,
  },
  loanInterestStatus: null,
  personalInfo: {
    firstName: null,
    lastName: null,
    address: {
      street: null,
      city: null,
      state: null,
      zipCode: null,
    },
  },
  isSubscribed: false,
};

export const onboardingReducer = (state, action) => {
  switch (action.type) {
    case ONBOARDING_ACTIONS.SET_INITIALIZED:
      return {
        ...state,
        initialized: true,
      };
    case ONBOARDING_ACTIONS.HYDRATE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        hydrated: true,
      };
    case ONBOARDING_ACTIONS.SET_LIVING_SITUATION:
      return {
        ...state,
        livingSituation: {
          isRenting: action.payload.isRenting,
          homeValue: action.payload.isRenting ? null : action.payload.homeValue,
          rent: action.payload.isRenting ? action.payload.rent : null,
        },
      };
    case ONBOARDING_ACTIONS.SET_PERSONAL_INFO:
      return {
        ...state,
        sendSMS: action.payload.sendSMS || state.sendSMS || false,
        personalInfo: {
          ...action.payload,
        },
      };
    case ONBOARDING_ACTIONS.SET_INCOME_INFO:
      return {
        ...state,
        income: {
          ...action.payload,
        },
      };
    case ONBOARDING_ACTIONS.SET_LOAN_INTEREST_STATUS:
      return {
        ...state,
        loanInterestStatus: action.payload.loanInterestStatus,
      };
    case ONBOARDING_ACTIONS.SET_CONSENT_INFO:
      return {
        ...state,
        dob: action.payload.dob,
      };
    case ONBOARDING_ACTIONS.SET_PHONE:
      return {
        ...state,
        primaryPhone: action.payload.primaryPhone,
        sendSMS: action.payload.sendSMS || state.sendSMS || false,
      };
    case ONBOARDING_ACTIONS.SET_GOAL:
      return {
        ...state,
        goal: action.payload.goal,
      };
    case ONBOARDING_ACTIONS.HAS_FATAL_ERROR:
      return {
        ...state,
        hasFatalError: true,
      };
    case ONBOARDING_ACTIONS.SET_SEND_EMAIL:
      return { ...state, sendEmail: action.payload };
    case ONBOARDING_ACTIONS.SET_SEND_SMS:
      return { ...state, sendSMS: action.payload };
    case ONBOARDING_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload };
    case ONBOARDING_ACTIONS.RESET_STATE:
      return { ...initialState, initialized: true };
    case ONBOARDING_ACTIONS.SET_SOURCE:
      return { ...state, source: action.payload?.toLowerCase() };
    default:
      return state;
  }
};
