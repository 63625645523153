export const ROUTE_PATHS = {
  HOME: '/',
  FIX_MY_CREDIT: '/fix-my-credit',
  BETTER: '/better',
  PRICING: '/pricing',

  // Authentication
  AUTHENTICATE: '/authenticate',
  LOGIN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  MAGIC_LINK_SENT: '/magic-link-sent',

  // TODO: Check verified and verify

  // Onboarding
  ONBOARDING: '/onboarding',
  TENANCY: '/onboarding/tenancy',
  ADDRESS: '/onboarding/address',
  INCOME: '/onboarding/income',
  LOAN_INTEREST: '/onboarding/loan-interest',
  REGISTER: '/onboarding/register',
  SUBSCRIPTION: '/onboarding/subscription',
  CONSENT: '/onboarding/consent',
  BUREAU_AUTH: '/onboarding/authorize',
  REVIEW_INFO: '/onboarding/verify',
  EDIT_INFO: '/onboarding/edit',
  FULL_SSN: '/onboarding/ssn',

  // B2C
  DASHBOARD: '/dashboard',
  RECOMMENDATION: '/dashboard/recommendation',
  ACCOUNT: '/dashboard/account',
  UPGRADE: '/dashboard/upgrade',
  MY_ACCOUNT: '/dashboard/account',
  MY_DEBTS: '/dashboard/debts',
  CREDIT_SCORE: '/dashboard/credit-score',
  LOAN_APPLICATION: '/dashboard/loan-application',

  // B2B
  LO_TOOL: '/mortgage-qualification',

  // MISC
  SERVICE_UNAVAILABLE: '/service-unavailable',
  ONBOARDING_ERROR: '/registration-failed',
  UNHANDLED_ERROR: '/error',
  BUREAU_NOT_FOUND: '/onboarding/bureau-error',
  LOCKED_OUT: '/locked-out',
};

export const BASE_ROUTE_PATHS = {
  DASHBOARD: '/dashboard',
  ONBOARDING: '/onboarding',
};
